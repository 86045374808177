import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {DOCUMENT, isPlatformBrowser} from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
  ) {}

  appendChildToBody(element: HTMLElement): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.appendChild(element)
    }
  }

  disablePageScroll(): void {
    this.document.body.style.overflow = 'hidden'
  }

  enablePageScroll(): void {
    this.document.body.style.overflow = 'auto'
  }

  scrollToTop(behavior: ScrollBehavior = 'smooth'): void {
    this.scrollTo({top: 0}, behavior)
  }

  scrollToBottom(behavior: ScrollBehavior = 'smooth'): void {
    this.scrollTo({top: this.document.body.scrollHeight}, behavior)
  }

  scrollToElement(element: Element, options: ScrollOptions = {behaviour: 'smooth'}): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }

    // scrollIntoView is the preferred way, but it doesn't support offset
    if (this.isNativeSmoothScrollSupported() && !options.topOffset) {
      element.scrollIntoView({
        behavior: options.behaviour ?? 'smooth',
        block: options.block ?? 'start',
      })
      return
    }

    const window = this.document.defaultView
    if (!window) throw Error('Window is not defined')

    const {behaviour, topOffset} = options
    const clientRect = element.getBoundingClientRect()
    const top = window.scrollY + clientRect.top + (topOffset ?? 0)
    const left = clientRect.left
    this.scrollTo({top, left}, behaviour)
  }

  scrollTo(position: {top?: number; left?: number}, behavior: ScrollBehavior = 'smooth'): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    const window = this.document.defaultView
    if (!window) throw Error('Window is not defined')

    const {top, left} = position
    return window.scrollTo({top, left, behavior})
  }

  private isNativeSmoothScrollSupported(): boolean {
    return 'scrollBehavior' in document.documentElement.style
  }
}

export type ScrollOptions = {
  behaviour?: ScrollBehavior
  block?: ScrollLogicalPosition
  topOffset?: number
}
